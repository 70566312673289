import "./libraries.min";
import "./lity.min";

(function ($) {
    // Global vars
    const $body = $("body");

    // Slide Menu

    $(".js-toggle-nav").click(function () {
        $body.toggleClass("menu-open");
    });

    $(".js-submenu").click(function () {
        var $this = $(this);

        $this.parent().toggleClass("submenu-open");
    });

    $(".js-toggle-search").click(function () {
        $body.toggleClass("search-active");
    });

    $(".faux-button").on("click", function () {
        $(this).addClass("video-start");
    });

    // Owl Carousel

    $("#owl-hp-text-slider").owlCarousel({
        loop: true,
        margin: 25,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 750,
        items: 1,
    });

    const owlSlideCarousel = $(".owl-slide-carousel");
    owlSlideCarousel.owlCarousel({
        loop: true,
        margin: 25,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 750,
        items: 1,
    });

    const owlTestimonialCarousel = $(".owl-testimonial-carousel");
    owlTestimonialCarousel.owlCarousel({
        loop: true,
        margin: 25,
        nav: false,
        dots: true,
        autoplay: true,
        smartSpeed: 750,
        autoplayTimeout: 8000,
        items: 1,
    });

    let owlFadeCarousel = $(".owl-fade-slider");

    owlFadeCarousel.each(function (index) {
        let timeout = $(this).data("timeout");
        $(this).owlCarousel({
            // use $(this)
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            smartSpeed: 1000,
            items: 1,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            autoplayTimeout: timeout,
        });
    });

    // Owl Carousel - Vertical

    var owl = $("#owl-carousel--vertical");

    owl.owlCarousel({
        autoplay: false,
        items: 1,
        dots: true,
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        rewind: true,
        autoplayHoverPause: true,
        animateOut: "slideOutUp",
        animateIn: "slideInUp",
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
    });

    const next = '<svg class="svgicon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/jandw/dist/img/icons.svg#angle-right"></use></svg>';
    const prev = '<svg class="svgicon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/jandw/dist/img/icons.svg#angle-left"></use></svg>';

    const owlAccreditationCarousel = $(".owl-accreditation-carousel");

    owlAccreditationCarousel.owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        autoplay: true,
        navText: [prev, next],
        smartSpeed: 500,
        autoplayTimeout: 1000,
        responsive: {
            0: {
                items: 1,
            },
            480: {
                items: 2,
                margin: 36,
            },
            960: {
                items: 4,
                margin: 36,
            },
            1200: {
                items: 5,
                margin: 36,
            },
            1400: {
                items: 6,
                margin: 36,
            },
        },
    });

    // Scroll to ID

    $(".js-scrollto").on("click", function () {
        var id = $(this).data("target");
        scrollTo(id);
    });

    function scrollTo(id) {
        var el;
        var scrollPos;
        var winW = $(window).width();

        if (id === "top") {
            scrollPos = 0;
        } else {
            el = $(id);
            scrollPos = el.offset().top;
        }

        $("html,body").animate({ scrollTop: scrollPos }, "slow");
    }

    // Responsive Background Images

    $(".js-responsive-bg").responsifybgimg();

    // Footcols

    $(".footcol h3").click(function () {
        $(this).next(".footcol__content").toggleClass("open");
    });

    // Sticky Header

    const $header = $(".site-header");
    var position = $(document).scrollTop();
    var force_show = false;
    var fixed = false;
    var mouseover = false;
    const winW = $(window).width();

    if ($("body").hasClass("page-template-page-internal-detail-plain") || $("body").hasClass("page-template-page-vision")) {
        force_show = true;
    }

    if (position >= 40 && winW > 959) {
        $header.addClass("is-fixed");
        $header.addClass("is-visible");
        fixed = true;
    }

    if (winW > 959) {
        $header.hover(
            function () {
                $(this).addClass("is-visible");
                $(this).removeClass("is-hidden");
                mouseover = true;
                console.log(mouseover);
            },
            function () {
                if (force_show == false) {
                    $(this).removeClass("is-visible");
                }

                mouseover = false;
                console.log(mouseover);
                if (position >= 40) {
                    $(this).addClass("is-hidden");
                }
            }
        );

        $(document).on("scroll", function () {
            var scroll = $(document).scrollTop();

            if (scroll > position) {
                //console.log('scrollDown');
                if (position >= 40) {
                    $header.addClass("is-fixed");
                    $header.addClass("is-hidden");
                    $header.removeClass("is-visible");

                    fixed = true;
                }
            } else {
                //console.log('scrollUp');
                if (position < 40) {
                    $header.removeClass("is-fixed");
                    $header.removeClass("is-hidden");

                    if (!mouseover) {
                        if (force_show == false) {
                            $header.removeClass("is-visible");
                        }
                    }
                    fixed = false;
                } else {
                    if (fixed === true) {
                        $header.removeClass("is-hidden");
                        $header.addClass("is-visible");
                    }
                }
            }

            setTimeout(function () {
                if (position < 40) {
                    $header.removeClass("is-fixed");
                    $header.removeClass("is-hidden");
                    if (!mouseover) {
                        if (force_show == false) {
                            $header.removeClass("is-visible");
                        }
                    }
                    fixed = false;
                }
            }, 200);

            position = scroll;
        });
    }

    // Toggle

    $(".js-toggle").click(function () {
        var $this = $(this);
        var $type = $this.data("toggle");

        if ($type == "sharer") {
            $this.toggleClass("active");
        }
    });

    // Read More Container

    var $el = $(".readmore");
    var itemHeight;
    var readMore = '<a href="javascript:void(0);" class="js-readmore__button readmore__link" title="Read More"><span class="readmore__link__text">Read More</span> <svg class="readmore__icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/jandw/img/icons.svg#angle-down"></use></svg></a>';

    $el.each(function (index, element) {
        var $this = $(this);
        itemHeight = $this.find(".readmore__text").height();

        if (itemHeight > 86) {
            $this.addClass("hidden-content");
            $this.find(".readmore__actions").prepend(readMore);
        }
    });

    $(".js-readmore__button").click(function () {
        var $textel = $(this).find(".readmore__link__text");

        $textel.text() == "Read More" ? $textel.text("Read Less").parent().attr("title", "Read Less") : $textel.text("Read More").parent().attr("title", "Read More");

        $(this).parent().prev(".js-readmore__container").toggleClass("open");
    });

    // Animate on Scroll

    $(".js-visible:in-viewport(0)").addClass("is-visible");

    $(window).scroll(function () {
        $(".js-visible:in-viewport(-200)").addClass("is-visible");
    });

    // Hide Blog Search Icon
    $(".post-search__search-field").on("focus", function () {
        $(".post-search__search-form").addClass("post-search__search-form--no-icon");
    });

    $(".post-search__search-field").on("focusout", function () {
        if ($(this).val() == "") {
            $(".post-search__search-form").removeClass("post-search__search-form--no-icon");
        }
    });

    //Lightslider
    let verticalHeight = "";
    if ($(".vertical-slider__caption").length > 0) {
        verticalHeight = 410;
    } else {
        verticalHeight = 330;
    }

    if ($(".vertical-slider--tall").length > 0) {
        verticalHeight = 480;
    }

    // Shorten for mobile
    if (winW < 480) {
        verticalHeight = verticalHeight * 0.65;
        console.log(verticalHeight);
    }

    $("#inline-slider").lightSlider({
        gallery: false,
        item: 1,
        slideMargin: 20,
        prevHtml: '<svg class="svgicon"><use xlink:href="https://www.jandwuk.com/wp-content/themes/jandw/dist/img/icons.svg#angle-left"></use></svg>',
        nextHtml: '<svg class="svgicon"><use xlink:href="https://www.jandwuk.com/wp-content/themes/jandw/dist/img/icons.svg#angle-right"></use></svg>',
    });
})(jQuery);
